import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import { doc, getDoc, setDoc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { FormControl, InputLabel, Select, MenuItem, Button, TextField, Box, Typography, Container, Grid, IconButton, Card } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAuth } from '../auth/AuthContext';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './ContestForm.css'; // Ensure the path to your CSS file is correct

const ContestForm = ({ contestId }) => {
    const [contestName, setContestName] = useState('');
    const [games, setGames] = useState([{ homeTeam: '', awayTeam: '', kickoffTime: null }]);
    const [cutoffTime, setCutoffTime] = useState(new Date());
    const [amountPerSquare, setAmountPerSquare] = useState('');
    // Define state hooks for payment details
    const [paypalLink, setPaypalLink] = useState('');
    const [venmoLink, setVenmoLink] = useState('');
    const [cashAppLink, setCashAppLink] = useState('');
    const [hasError, setHasError] = useState(false);
    const [upcomingGames, setUpcomingGames] = useState([]);

    const navigate = useNavigate();
    const { currentUser } = useAuth();

    useEffect(() => {
        console.log('cutoff time:', cutoffTime)
    }, [cutoffTime])

    // Effect for fetching contest data if editing
    useEffect(() => {
        if (contestId) {
            const contestRef = doc(db, 'contests', contestId);

            getDoc(contestRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    console.log('data.cutofftime: ', data.cutoffTime)
                    setContestName(data.name);
                    setGames(data.games);
                    setCutoffTime(data.cutoffTime.toDate()); // Assuming cutoffTime is a Firestore Timestamp
                    setAmountPerSquare(data.amountPerSquare);
                    // Set payment details if available
                    setPaypalLink(data.paymentDetails?.paypal || '');
                    setVenmoLink(data.paymentDetails?.venmo || '');
                    setCashAppLink(data.paymentDetails?.cashApp || '');
                } else {
                    console.log('No such document!');
                }
            });
        }
    }, [contestId]);

    useEffect(() => {
        setHasError(false)
    }, [contestName, games])

    useEffect(() => {
        if (!contestId) {
            if (games.length == 1) {
                setCutoffTime(games[0].kickoffTime)
            } else if (games.length > 1) {
                const earliestGameTime = games
                    .map(game => new Date(game.kickoffTime))
                    .filter(time => !isNaN(time.getTime()))
                    .reduce((a, b) => a < b ? a : b, new Date(Infinity));

                if (!isNaN(earliestGameTime.getTime())) {
                    setCutoffTime(earliestGameTime);
                }
            }
        }

    }, [games]);

    useEffect(() => {
        if (!currentUser) {
            // User is not logged in
            navigate('/login')
        }
    }, [currentUser]);

    const fetchESPNData = async () => {
        try {
            const res = await fetch('https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?limit=1000&dates=20241217-20250228');
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return await res.json();
        } catch (error) {
            console.error('Error fetching ESPN data:', error);
        }
    }


    useEffect(() => {
        // fetch('https://us-central1-squares-prod-62239.cloudfunctions.net/fetchESPNData')
        fetchESPNData()
            .then(data => {
                if (data) {
                    const currentTime = new Date();
                    const futureGames = data.events.filter(game =>
                        new Date(game.date) > currentTime
                    ).map(game => ({
                        id: game.competitions[0].id, // competition ID
                        homeTeam: game.competitions[0].competitors.find(team => team.homeAway === 'home').team,
                        awayTeam: game.competitions[0].competitors.find(team => team.homeAway === 'away').team,
                        kickoffTime: game.date,
                        homeTeamColor: game.competitions[0].competitors.find(team => team.homeAway === 'home').team.color, // home team color
                        homeTeamAltColor: game.competitions[0].competitors.find(team => team.homeAway === 'home').team.alternateColor, // home team color
                        awayTeamColor: game.competitions[0].competitors.find(team => team.homeAway === 'away').team.color, // away team color
                        awayTeamAltColor: game.competitions[0].competitors.find(team => team.homeAway === 'away').team.alternateColor, // away team color
                        homeTeamId: game.competitions[0].competitors.find(team => team.homeAway === 'home').team.id, // home team ID
                        awayTeamId: game.competitions[0].competitors.find(team => team.homeAway === 'away').team.id // away team ID
                    }));
                    setUpcomingGames(futureGames);
                    console.log('future games: ', futureGames)
                }
            })
            .catch(error => console.error('Error fetching data: ', error));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Prepare contest data
        const contestData = {
            name: contestName,
            games: games.map(game => ({
                ...game,
                homeTeam: game.homeTeam,
                awayTeam: game.awayTeam,
                kickoffTime: game.kickoffTime,
                // ... other game properties
            })),
            cutoffTime: cutoffTime,
            amountPerSquare: amountPerSquare,
            paymentDetails: {
                paypal: paypalLink,
                venmo: venmoLink,
                cashApp: cashAppLink,
            },
            // Include other fields as necessary
        };

        try {
            if (contestId) {
                // Update existing contest
                const contestRef = doc(db, 'contests', contestId);
                await updateDoc(contestRef, contestData);
                navigate(`/contest/${contestId}`);
            } else {
                // Create new contest
                const docRef = await addDoc(collection(db, 'contests'), contestData);
                navigate(`/contest/${docRef.id}`);
            }
        } catch (error) {
            console.error("Error writing document: ", error);
        }
    };


    const handleContestNameChange = (e) => {
        setContestName(e.target.value);
    };

    const handleGameChange = (index, e, team) => {
        const newGames = [...games];
        newGames[index][team] = e.target.value;
        setGames(newGames);
    };

    const addGame = () => {
        setGames([...games, { homeTeam: '', awayTeam: '' }]);
    };


    const handleSelectGame = (index, selectedGameId) => {
        const selectedGame = upcomingGames.find(game => game.id === selectedGameId);
        if (selectedGame) {
            const newGames = [...games];
            newGames[index] = {
                ...newGames[index],
                id: selectedGame.id,
                homeTeam: selectedGame.homeTeam.displayName,
                awayTeam: selectedGame.awayTeam.displayName,
                kickoffTime: new Date(selectedGame.kickoffTime),
                homeTeamColor: selectedGame.homeTeamColor,
                awayTeamColor: selectedGame.awayTeamColor,
                homeTeamId: selectedGame.homeTeamId,
                awayTeamId: selectedGame.awayTeamId,
                competitionId: selectedGame.id
            };
            setGames(newGames);
        }
    };

    return (
        <Container maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" gutterBottom>
                    {contestId ? 'Edit Contest' : 'Create Contest'}
                </Typography>
                <form onSubmit={handleSubmit} noValidate>
                    <TextField
                        label="Contest Name"
                        value={contestName}
                        onChange={handleContestNameChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                    {games.map((game, index) => (
                        <Grid container spacing={2} key={index} alignItems="flex-end" style={{ marginBottom: 10 }}>
                            <Grid item xs={12} sm={2}>
                                <FormControl fullWidth>
                                    <InputLabel variant='filled'>Games</InputLabel>
                                    <Select
                                        value={game.id || 'Games'}
                                        onChange={
                                            (e) => {
                                                handleSelectGame(index, e.target.value)
                                            }}
                                    >
                                        {upcomingGames.map(upcomingGame => (
                                            <MenuItem key={upcomingGame.id} value={upcomingGame.id}>
                                                {upcomingGame.homeTeam.shortDisplayName} vs {upcomingGame.awayTeam.shortDisplayName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    label={`Home Team`}
                                    value={game.homeTeam}
                                    onChange={(e) => handleGameChange(index, e, 'homeTeam')}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    label="Away Team"
                                    value={game.awayTeam}
                                    onChange={(e) => handleGameChange(index, e, 'awayTeam')}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            {index === games.length - 1 && (
                                <Grid item xs={12} sm={2}>
                                    <IconButton onClick={addGame} color="primary">
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                    ))}

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label="Contest Cutoff Time"
                            value={cutoffTime}
                            onChange={(newValue) => {
                                setCutoffTime(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    <Card variant="outlined" sx={{ p: 2, mt: 3, mb: 2 }}>
                        <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                            Payment Details
                        </Typography>
                        <TextField
                            label="Amount Per Square"
                            value={amountPerSquare}
                            onChange={(e) => setAmountPerSquare(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                        />
                        <TextField
                            label="PayPal Link"
                            value={paypalLink}
                            onChange={(e) => setPaypalLink(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="Venmo Link"
                            value={venmoLink}
                            onChange={(e) => setVenmoLink(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="Cash App Link"
                            value={cashAppLink}
                            onChange={(e) => setCashAppLink(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                    </Card>

                    <Box sx={{ mt: 3 }}>
                        <Button type="submit" variant="contained" color="primary">
                            {contestId ? 'Update Contest' : 'Create Contest'}
                        </Button>
                        {hasError ? <p style={{ color: 'tomato' }}>Error Creating Contest</p> : null}
                    </Box>
                </form>
            </Box>

        </Container>
    );
};

export default ContestForm;
