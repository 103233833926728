import React, { useEffect, useState } from 'react';
import './PreStartGrid.css'; // Import CSS file for additional styling
import _ from 'underscore'
import { useAuth } from '../auth/AuthContext';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { logEvent } from "firebase/analytics";
import { appAnalytics } from '../firebase-config';

const PreStartGrid = ({ onSquareClick, gridData }) => {

    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [gamesCount, setGamesCount] = useState(null)
    const totalSquares = 100

    useEffect(() => {
        console.log('griddata: ', gridData)
        logEvent(appAnalytics, 'page_view', { uid: currentUser?.uid, page_path: '/PreStartGrid' });
    }, [gridData])

    useEffect(() => {
        const adjustHeaderWidth = () => {
            const gridContainer = document.querySelector('.grid-container');
            const awayTeamHeader = document.querySelector('.away-team');
            if (gridContainer && awayTeamHeader) {
                const gridContainerWidth = gridContainer.offsetWidth;
                awayTeamHeader.style.width = `${gridContainerWidth + 40}px`;
            }
        };

        // Adjust the width on component mount
        adjustHeaderWidth();

        // Add event listener for window resize
        window.addEventListener('resize', adjustHeaderWidth);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', adjustHeaderWidth);
    }, []);


    const goToPaymentOptions = () => {
        navigate('/payment-options', { state: { paymentDetails: gridData.paymentDetails } });
        logEvent(appAnalytics, 'click-to-payment', { uid: currentUser?.uid });

    };


    const countUserSquares = () => {
        // Find the participant object for the current user and return the count of their selected squares
        const participant = gridData?.participants?.find(p => p.id === currentUser?.uid);
        return participant?.squares?.length || 0;
    };

    const calculateTotalAmount = () => {
        const numberOfSquares = countUserSquares();
        const amountPerSquare = parseFloat(gridData?.amountPerSquare || 0);
        const gamesCount = gridData.games?.length || 0
        return numberOfSquares * amountPerSquare * gamesCount;
    };

    const amountPaidByUser = () => {
        const participant = gridData?.participants?.find(p => p.id === currentUser?.uid);
        return participant?.amountPaid || 0;
    };

    const totalAmountOwed = calculateTotalAmount();
    const userAmountPaid = amountPaidByUser();

    const isSquareSelectedByCurrentUser = (squareNumber) => {
        return gridData.participants?.some(participant =>
            participant.id === currentUser?.uid && participant.squares?.includes(squareNumber)
        );
    };

    const getSquaresRemaining = () => {
        // Calculate the total number of squares taken
        const totalSquaresTaken = gridData?.participants.reduce((acc, participant) => {
            return acc + (participant.squares ? participant.squares.length : 0);
        }, 0);
        // Calculate the number of squares remaining
        return totalSquares - totalSquaresTaken;
    }

    const handleSquareClick = (squareNumber) => {
        if (currentUser) {
            if (isSquareSelectedByCurrentUser(squareNumber)) {
                // Deselect the square if it's already selected by the current user
                onSquareClick(squareNumber, true); // The second parameter indicates a deselect action
            } else {
                // Select the square if it's not selected
                onSquareClick(squareNumber, false);
            }
        }

    };

    const renderGridSquares = () => {
        const squares = [];
        for (let row = 0; row < 10; row++) {
            for (let col = 0; col < 10; col++) {
                const squareNumber = row * 10 + col + 1;
                let squareDisplay = squareNumber;
                let isSelected = false;
                gridData.participants?.forEach(participant => {
                    if (participant.squares?.includes(squareNumber)) {
                        squareDisplay = participant.squareName;
                        if (participant.id === currentUser?.uid) {
                            isSelected = true; // Mark as selected if it's the current user
                        }
                    }
                });
                const key = `square-${squareNumber}`;
                squares.push(
                    <div
                        style={isSelected ? { backgroundColor: 'rgba(0,215,45,.7)', justifyContent: 'center' } : null}
                        key={key} className="grid-square" onClick={() => handleSquareClick(squareNumber)}>
                        {squareDisplay}
                    </div>
                );
            }
        }
        return squares;
    };


    console.log('userAmountPaid: ', userAmountPaid)
    console.log('totalOwed: ', totalAmountOwed)
    const squaresRemaining = getSquaresRemaining()
    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    // height: '30vh', // Adjust this value as needed
                    // marginTop: '20px',
                    marginBottom: '20px'
                }}
            >

                <InstructionCard squaresRemaining={squaresRemaining} />
                <Card className="selected-squares-card">
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            Squares Selected: {countUserSquares()}
                        </Typography>
                        <Typography color="textSecondary">
                            Amount per Square: ${gridData?.amountPerSquare} x {gridData.games?.length} game{gridData.games?.length > 1 ? 's' : ''}
                        </Typography>
                        <Typography variant="body2" component="p">
                            Total Owed:${calculateTotalAmount().toFixed(2)}
                        </Typography>
                        {userAmountPaid < totalAmountOwed && totalAmountOwed > 0 ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={goToPaymentOptions}
                                sx={{
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                    padding: '10px 24px',
                                    borderRadius: '20px', // rounded corners
                                    marginTop: '10px', // Added margin for spacing
                                }}
                            >
                                Pay Now ${totalAmountOwed.toFixed(2)}
                            </Button>
                        )
                            :
                            userAmountPaid >= totalAmountOwed && totalAmountOwed > 0 &&
                            <Typography bgcolor={'rgba(15, 255, 100, 0.5)'}>Thank you for your payment!</Typography>
                        }

                    </CardContent>
                </Card>
                <Box style={{ margin: 20 }}>
                    <Typography variant="h6">
                        Squares Remaining: {squaresRemaining}
                    </Typography>
                </Box>
            </Box>

            <div className="pre-football-grid-container">
                <div className="team-header home-team">{gridData.games?.length > 1 ? 'Home Team' : gridData.games[0].homeTeam}</div>
                <div className="team-header away-team">{gridData.games?.length > 1 ? 'Away Team' : gridData.games[0].awayTeam}</div>
                <div className="grid-container">
                    {renderGridSquares()}
                </div>
            </div>
        </div>

    );
};

export default PreStartGrid;




const InstructionCard = ({ squaresRemaining }) => (
    <Box sx={{ marginX: '10vw', marginY: 2 }}>
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Game Overview:
                </Typography>
                <Typography paragraph>
                    After all squares are claimed, numbers from 0 to 9 are assigned randomly across rows and columns for each team. These digits correspond to the last number of each team's score. For example, if the game score is Chiefs 17 - Steelers 14, the square intersecting at Chiefs 7 and Steelers 4 is the winner.
                </Typography>
                <Typography variant="h6">
                    Games:
                </Typography>
                <div class="games">
                    <div class="game">
                        <div class="teams">Chiefs vs. Steelers</div>
                        <div class="time">Wed, Dec 25 - 1:00 PM EST</div>
                    </div>
                    <div class="game">
                        <div class="teams">Ravens vs. Texans</div>
                        <div class="time">Wed, Dec 25 - 4:30 PM EST</div>
                    </div>
                    <div class="game">
                        <div class="teams">Seahawks vs. Bears</div>
                        <div class="time">Thu, Dec 26 - 8:15 PM EST</div>
                    </div>
                </div>
                <Typography variant="h6">
                    Prize Distribution:
                </Typography>
                <Typography paragraph>
                    - End of 1st Quarter: $100<br />
                    - End of 2nd Quarter: $100<br />
                    - End of 3rd Quarter: $100<br />
                    - End of Game: $190<br />
                </Typography>

            </CardContent>
        </Card>
    </Box>
);