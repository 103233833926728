import React, { useEffect, useState } from 'react';
import './PostStartGrid.css'; // Import CSS file for additional styling
import { useAuth } from '../auth/AuthContext';
import { logEvent } from "firebase/analytics";
import { appAnalytics } from '../firebase-config';
import { Button } from '@mui/material'

const PostStartGrid = ({ game, participants, gameScores }) => {
    const { currentUser } = useAuth();
    const [awayScoreLSD, setAwayScoreLSD] = useState(null);
    const [homeScoreLSD, setHomeScoreLSD] = useState(null);

    useEffect(() => {
        console.log('game: ', game)
        logEvent(appAnalytics, 'page_view', { uid: currentUser?.uid, page_path: '/PostStartGrid' });
    }, [game])

    useEffect(() => {
        console.log('gameScores', gameScores.away)
        if (gameScores.away && gameScores.away.score) {
            setAwayScoreLSD(getLeastSignificantDigit(parseInt(gameScores.away.score, 10)));
        }
        if (gameScores.home && gameScores.home.score) {
            setHomeScoreLSD(getLeastSignificantDigit(parseInt(gameScores.home.score, 10)));
        }
        console.log('gameScores', gameScores);
    }, [gameScores]);


    useEffect(() => {
        const adjustHeaderWidth = () => {
            const gridContainer = document.querySelector('.grid-container');
            const awayTeamHeader = document.querySelector('.away-team');
            if (gridContainer && awayTeamHeader) {
                const gridContainerWidth = gridContainer.offsetWidth;
                awayTeamHeader.style.width = `${gridContainerWidth + 40}px`;
            }
        };

        // Adjust the width on component mount
        adjustHeaderWidth();

        // Add event listener for window resize
        window.addEventListener('resize', adjustHeaderWidth);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', adjustHeaderWidth);
    }, []);

    const isSquareHighlighted = (row, col) => {
        const awayTeamLSDIndex = game.awayTeamNumbers.indexOf(awayScoreLSD) + 1;
        const homeTeamLSDIndex = game.homeTeamNumbers.indexOf(homeScoreLSD) + 1;
        return row === homeTeamLSDIndex || col === awayTeamLSDIndex;
    };

    const isSquareSuperHighlighted = (row, col) => {
        const awayTeamLSDIndex = game.awayTeamNumbers.indexOf(awayScoreLSD) + 1;
        const homeTeamLSDIndex = game.homeTeamNumbers.indexOf(homeScoreLSD) + 1;
        return row === homeTeamLSDIndex && col === awayTeamLSDIndex;
    };

    const renderGridSquares = () => {
        const squares = [];

        // Loop through each row.
        for (let row = 0; row < 11; row++) {
            // Loop through each column.
            for (let col = 0; col < 11; col++) {
                // Initialize variables for display content and CSS classes.
                let squareDisplay = '';
                let isSelected = false;
                let highlightClass = '';

                if (row === 0 && col > 0) {
                    // Assign away team numbers to the first row, excluding the first cell.
                    squareDisplay = game.awayTeamNumbers[col - 1];
                    highlightClass = awayScoreLSD === squareDisplay ? 'highlighted' : '';
                } else if (col === 0 && row > 0) {
                    // Assign home team numbers to the first column, excluding the first cell.
                    squareDisplay = game.homeTeamNumbers[row - 1];
                    highlightClass = homeScoreLSD === squareDisplay ? 'highlighted' : '';
                } else if (row > 0 && col > 0) {
                    // Calculate the square number for participant squares, starting at 0.
                    const squareNumber = (row - 1) * 10 + (col - 1);

                    // Check if this square is selected by any participant.
                    participants?.forEach(participant => {
                        if (participant.squares?.includes(squareNumber + 1)) {
                            squareDisplay = participant.squareName;
                            isSelected = participant.id === currentUser?.uid;
                        }
                    });

                    // Check if this square should be highlighted or super-highlighted.
                    if (isSquareSuperHighlighted(row, col)) {
                        highlightClass = 'super-highlighted';
                    } else if (isSquareHighlighted(row, col)) {
                        highlightClass = 'highlighted';
                    }
                }

                // Create a style object for conditional styling.
                const squareStyle = isSelected ? { color: '#118002', fontWeight: 'bold', justifyContent: 'center' } : {};

                // Construct a unique key for each square.
                const key = `square-${row}-${col}`;

                // Push a new div to the squares array for each grid cell.
                squares.push(
                    <div
                        style={squareStyle}
                        key={key}
                        className={`grid-square ${row === 0 || col === 0 ? 'team-number' : ''} ${highlightClass}`}
                    >
                        {squareDisplay}
                    </div>
                );
            }
        }

        // Return the full array of squares to be rendered.
        return squares;
    };

    const handlePrint = () => {
        window.print();
    };

    // Function to get the least significant digit
    const getLeastSignificantDigit = (score) => score % 10;

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
                <Button id={'btn-print'} onClick={handlePrint} variant="contained" color="primary">
                    Print
                </Button>
            </div>
            <div className="post-football-grid-container">
                <div className="team-header home-team"
                    style={{
                        backgroundImage: `linear-gradient(to bottom, ${game.homeTeamColor}, ${game.homeTeamAltColor}`,
                        color: '#FFFFFF',
                    }}
                >{game.homeTeam}</div>
                <div className="team-header away-team"
                    style={{
                        backgroundImage: `linear-gradient(to right, ${game.awayTeamColor}, ${game.awayTeamAltColor}`,
                        color: '#FFFFFF',
                    }}
                >{game.awayTeam}</div>
                <div className="grid-container">
                    {renderGridSquares()}
                </div>
            </div>
        </div >
    );
};

// Utility function to lighten a color
function lightenColor(color, percent = 30) {
    const num = parseInt(color.replace("#", ""), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        G = ((num >> 8) & 0x00ff) + amt,
        B = (num & 0x0000ff) + amt;

    return (
        "#" +
        (0x1000000 +
            (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
            (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
            (B < 255 ? (B < 1 ? 0 : B) : 255))
            .toString(16)
            .slice(1)
    );
}

export default PostStartGrid;
